import { Box, ChakraProvider } from '@chakra-ui/react'
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PriceTypeProvider } from 'helper/price'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { IntlProvider } from 'react-intl'
import { GlobalStyle } from 'theme/stocker/globals'
import { stockerTheme } from 'theme/stocker/theme'
import '../helper/nprogress.css'

// Binding events.
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(() => new QueryClient())
  const router = useRouter()

  return (
    <IntlProvider locale="de" messages={pageProps.translations} onError={() => {}}>
      <CookiesProvider>
        <Head>
          <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png"/>
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png"/>
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png"/>
          <title>Konfigurator | Stocker Kaminsysteme</title>
        </Head>
        <PriceTypeProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false}/>
            <Hydrate state={pageProps.dehydratedState}>
              <ChakraProvider theme={stockerTheme}>
                <GlobalStyle/>
                <Box py="10px">
                  <Component {...pageProps}/>
                </Box>
              </ChakraProvider>
            </Hydrate>
          </QueryClientProvider>
        </PriceTypeProvider>
      </CookiesProvider>
    </IntlProvider>
  )
}

export default MyApp
