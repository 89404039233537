import type React from 'react'
import type { PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'

export enum PriceType {
  Partner = '0',
  Base = '1',
}

export function _usePriceType () {
  const [priceType, setPriceType] = useState(getPriceType())

  useEffect(() => {
    // set default value if priceType is null
    if (priceType === null) {
      persistPriceType(PriceType.Base)
    }
  }, [priceType])

  function persistPriceType (value: PriceType) {
    setPriceType(value)
    localStorage.setItem('priceType', value)
  }

  return {
    priceType: priceType ?? PriceType.Base,
    persist: persistPriceType,
  }
}

export function getPriceType () {
  return typeof window !== 'undefined' ? localStorage.getItem('priceType') : PriceType.Base
}

export const PriceContext = createContext<ReturnType<typeof _usePriceType>>({ priceType: PriceType.Base, persist: (value: PriceType) => {} })

export function usePriceType () {
  return useContext(PriceContext)
}

export const PriceTypeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const value = _usePriceType()

  return (
    <PriceContext.Provider value={value}>
      {children}
    </PriceContext.Provider>
  )
}
